.vertical-divider {
    display: flex;
    align-items:flex-start;
}

.divider {
    border-left: 2px solid #000; /* Adjust thickness and color as needed */
    height: 2300px; /* Adjust height as needed */
    margin:0%; /* Adjust margin as needed */
}

/* Optionally, you can style the left and right components */
.left-component {
    margin: 10px;
    margin-top: 100px;
    width: 280px;
}

.right-component {
    margin-left: 100px;
}